/* Base styles for header */
.header {
  position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100px;
    top: 0;
    background-color: white;
    transition: background-color 0.3s ease;
    z-index: 9999999999999999999;
    
  }
  
  .header--scrolled {
    background-color:white;
  }
  
  /* Logo styles */
  .header__logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 60px;
  }
  
  .logo {
    height: 70px;
    margin-right: 0px;
  }
  
  .brand-name {
    font-family: 'Reggae One', cursive;
    font-size: 1rem;
    font-weight: 500;
    color: #000000;
    margin-top: -15px;
  }
  
  /* Menu styles */
  .header__menu ul {
    display: flex;
    list-style: none;
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
  }
  
  .header__menu ul li {
    margin: 0 15px;
    border: 1px solid #009246; /* Green border */
    border-radius: 5px;
    padding: 5px 10px;
    transition: background-color 0.3s ease;
    color: #009246;
  }
  .header__menu ul li:hover{
   color: #fff !important;
  }
  
  .header__menu ul li a {
    text-decoration: none;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    color: #009246; /* Menu text color */
  }
  
  .header__menu ul li.selected,
  .header__menu ul li:hover {
    background-color: #009246; /* Background changes to green when selected or hovered */
    cursor: pointer;
  }
  .header__menu ul li:hover .web-dropdown-toggle,
  .header__menu ul li.selected .web-dropdown-toggle
   {
 color: #ffffff;
  }
  
  .header__menu ul li.selected a,
  .header__menu ul li:hover a {
    color: white; /* Text turns white when selected or hovered */
  }
 .web-sub-manu{
    color: #009246 !important;
  }
  .manu-dropdown-web :hover{
    color: #fff !important;
  }
  
  /* Order button styles */
  .header__order .order-btn {
    padding: 10px 20px;
    background-color: #009246;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 20px;
  }
  
  .header__order .order-btn:hover {
    background-color: #d51f1f;
  }

  /* Responsive Styles */

  @media (max-width: 480px) {
    .brand-name {
      font-size: 1.2rem; /* Smaller brand name on extra small screens */
    }
  
    .header__menu ul li a {
      font-size: 14px; /* Smaller font size for menu items */
    }
  
    .header__order .order-btn {
      padding: 8px 16px; /* Adjust button size */
     
    }
    .manu-dropdown-web{
      box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2) !important;
    }
    
    
  }
  



  
  .mobile-header-drawer-open {
    transform: translateX(0); /* Slide into view */
  }
  
  .mobile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px;
    background-color: #ffffff;
    color: #000000;
    position: relative;
    width: 100%;

  }
  
  .mobile-header-left {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-right: 20px;
    
    margin-left: 20px;
  }
  
  .mobile-header-logo {
    height: 50px; /* Adjust size as needed */
    margin-bottom: 10px;
  }
  
  .mobile-header-brand-name {
    font-family: 'Reggae One', cursive;
    font-size: 0.7rem;
    font-weight: 500;
    color: #000000;
    margin-top: -15px;
  }
  
  .mobile-header-right {
    display: flex;
    align-items: center;
    position: relative;
    transition: all 0.3s ease-in-out;
    
  }
  
  .mobile-header-extra-button {
    padding: 10px 20px;
    background-color: #009246;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 20px;
  }

  .mobile-header-extra-button:hover {
    background-color: #d51f1f;
  }

  
  /* Move extra button to the right when drawer opens */
  .mobile-header-button-move-right {
    justify-content: flex-end;
  }
  
  .mobile-header-drawer-toggle {
    background-color: transparent;
    border: none;
    color: #009246;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    margin-right: 20px;
   
  }
  
  /* Drawer styles */
  .mobile-header-drawer {
    position: absolute;
    top: 60px;
    right: -100%;
    width: 200px;
    background-color: #cfcfcf;
    transition: right 0.3s ease-in-out;
    z-index: 999; /* Ensure it's on top */
    /* box-shadow: #252222; */
    background-color: #ffffff;
    box-shadow: 2px 2px 10px 0px rgba(37, 34, 34, 0.5);
  }
  
  .mobile-header-drawer-open {
    right: 0; /* Bring the drawer into view */
  }
  
  .mobile-header-drawer ul {
    list-style: none;
    border: none;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .mobile-header-drawer ul li {
    margin: 10px 0;
    cursor: pointer;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    color: #009246; /* Menu text color */
    padding: 5px;
  }
  .mobile-header-drawer ul li.selected,
  .mobile-header-drawer ul li:hover {
    background-color: #009246; /* Background changes to green when selected or hovered */
    cursor: pointer;
    color: white;
    /* display: flex; */
  }
  .mobile-header-drawer ul li.selected .dropdown-toggle {
    color: #fff;
  }
  .mobile-header-drawer ul li{
    display: flex;
  }
  
  .mobile-header-drawer ul li.selected a,
  .mobile-header-drawer ul li:hover a{
    color: white; /* Text turns white when selected or hovered */
  }

.mobile-header {
  position: fixed; /* Fixes the mobile header to the top */
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999999;
  
  background-color: white; /* Set a background color */
  transition: background-color 0.3s; /* Smooth transition for background change */
}

/* Optional: Add a shadow or change background on scroll */
.header--scrolled, .mobile-header--scrolled {
  background-color: rgba(255, 255, 255, 255); /* Slightly transparent on scroll */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Add shadow */
}

/* Add this CSS rule */
body {
  margin-top: 60px; /* 100px to account for the header height + extra space */
}

.mobile-header-drawer ul li a{
  display: flex;
  text-decoration: none;
  color: #009246 ;
  width: 100%;
  height: 100% !important;
  padding: 5px;
}
  .mobile-dropdown li a{
    color: #009246 !important;
  }
  .mobile-dropdown li a:hover{
    color: #ffffff !important;
  }



/* new css */
.header__menu .manu-dropdown-web
{
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  list-style: none;
  padding-bottom: 20px;
  margin-left: -10px;
  margin-top: 5px;
}
.manu-dropdown-web li{
  border: none !important;
  padding: 10px !important;
  margin-top: 15px !important;
}
.manu-dropdown-web{
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2) !important;
}


.mobile-dropdown {
  position: absolute;
  background-color: #fff;
  list-style: none;
  padding: 0;
  /* margin-left: 0; */
  border: 1px solid #ccc;
  margin-top: 40px;
  box-shadow: 4px 4px 10px 2px rgba(0, 0, 0, 0.2);
  width: 110px;
}


.mobile-header .mobile-dropdown li {
  padding: 8px 16px;
  cursor: pointer;
  width: 100%;

}
.header__menu .dropdown li {
  padding: 8px 16px;
  cursor: pointer;
}

.header__menu .manu-dropdown-web li:hover,
.mobile-header .mobile-dropdown li:hover {
  background-color: #ff0000;
}



.dropdown-toggle {
  cursor: pointer; /* Changes cursor to pointer on hover */
  padding: 0.5rem; /* Add some padding for better clickability */
  font-size: 1rem; /* Adjust font size as needed */
  color: green; /* Change text color */
  background: none; /* No background */
  border: none; /* No border */
  outline: none; /* Remove outline */
  display: inline-block; /* Ensures it behaves like a button */
  margin-left: 0.5rem; /* Space between menu item and toggle */
  transition: color 0.3s; /* Smooth transition for hover effect */
}
.web-dropdown-toggle {
  cursor: pointer; /* Changes cursor to pointer on hover */
  margin-left: 3px;
  font-size: 14px; /* Adjust font size as needed */
  color: green; /* Change text color */
  background: none; /* No background */
  border: none; /* No border */
  outline: none; /* Remove outline */
  display: inline-block; /* Ensures it behaves like a button */
  margin-right: -10px;
  transition: color 0.3s; /* Smooth transition for hover effect */
}
.web-dropdown-toggle:hover{
  color: #fdfdfd;
}

.dropdown-toggle:hover {
  color: #ffffff; /* Change color on hover (optional) */
}


/* heder sumanu lock */
/* Add this to your Header.css file */



.mobile-header-submanu{
  padding: 0 !important;
}
.selected-submenu {
  background-color: red !important;
  color: white !important; /* Optional: for better readability */
}
.selected-submenu .web-sub-manu,
.selected-submenu .mobile-header-submanu{
  color: white !important; /* Optional: for better readability */
}


