.faq{
    width: 100%;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
}
.faq-left{
    width: 40%;
}
.sub-faq{
    height: 100%;
    margin-left: 50px;
}
.faq-right{
    width: 50%;
    align-items: center;
    display: flex;
}
.faq-right img{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
} 
.faq-title{
    text-align: center;
    color: #CD212A;
    font-size: 2rem;
    font-family: "roboto";
    font-weight: 600;

}
@media (max-width: 768px) {
    .faq{
       
        flex-direction: column-reverse;
    }
    .faq-left,.faq-right{
        width: 100%;
    }
    .sub-faq{
        margin-left: 0;
    }
}