
@import url('https://fonts.googleapis.com/css2?family=Lavishly+Yours&family=Raleway:ital,wght@0,100..900;1,100..900&family=Spectral+SC:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
.fritime {
  background-color: #1B1B1B;
  border: 1px solid black;
  color: white;
  margin-left: 100px;
  font-size: 32px;
  font-family: Raleway;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 164px;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px 0 0 15px;

}


.slot {
  background-color: #ffffff;
  border: #009246;
  border-radius: 10px;
  color: #009246;

  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 164px;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;

}


.pizzaImage2 {

  width: 50% !important;  
  overflow: hidden;
}
.pizzaImage2 img{
 object-fit: cover; 
 overflow: hidden;
}

.overlayTextfriday{
  position: absolute;
bottom: -760px;
  right: 1000px;
  
  color: rgb(255, 255, 255);
  font-size: 64px;
  text-align: center;
  padding: 10px;
  font-family: 'Spectral SC';

}



.overlayText {
  position: absolute;
  top: 90%;
  right: 200px;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 64px;
  text-align: center;

  padding: 10px;
  border-radius: 5px;
  font-family: 'Spectral SC';
}

.overlayTextTo {
  position: absolute;
  top:110%;
  right: 320px;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 64px;
  text-align: center;

  padding: 10px;
  border-radius: 5px;
  font-family: 'Spectral SC';

}

.overlayTextSat {
  position: absolute;
  top: 110%;
  right: 0px;
  transform: translate(-10%, 50%);
  color: white;
  font-size: 64px;
  text-align: center;

  padding: 10px;
  border-radius: 5px;
  font-family: 'Spectral SC';

}


.overlayTextToo {
  position: absolute;

  transform: translate(380px, -450px);
  color: white;

  text-align: center;
  font-size: 64px;
  padding: 10px;
  border-radius: 5px;
  font-family: 'Spectral SC';
}

.overlayTextsatur {
  position: absolute;

  transform: translate(30px, -550px);
  color: white;
  font-size: 24px;
  text-align: center;
  font-size: 64px;
  padding: 10px;
  border-radius: 5px;
  font-family: 'Spectral SC';
}

.overlayTextSunday {
  position: absolute;
  font-size: 64px;
  transform: translate(410px, -350px);
  color: white;

  text-align: center;

  padding: 10px;
  border-radius: 5px;
  font-family: 'Spectral SC';
}


.pizzabookatable {
  display: flex;
  margin: auto;
  height: 80vh !important;
  width: 100%;


}


.timeSlotGroupwidth {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}


.timeSlotGroupwidth .slots {
  margin: 0;
  margin-top: 10px;
}

.fritime,
.fritime1 {
  margin-bottom: 0;
}


.timeSlotGroupwidth .slots:first-of-type {
  margin-top: 5px;
}



.timeSlotGroup {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 30px;

}

.time {
  font-weight: bold;
}



.timeSlotGroup {
  margin-bottom: 15px;
}



.timeDiv {
  display: flex;
  flex-direction: column;
  align-items:center;
  width: 50%;
  margin: auto;
  justify-content: center;
}

.timeSlotGroup {
  margin-bottom: 15px;
  text-align: justify;
  width: 95%;
  justify-content: center;
}

.booktime {
  margin-bottom: 5px;
  border: 1px solid black;
  width: 164px;
  height: 53px;
  background-color: #1B1B1B;
  font-size: 32px;
  font-family: Raleway;
  border-radius: 10px 0 0 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}


.booktimewhite {
  margin-bottom: 5px;
  font-size: 32px;
  font-family: Raleway;
  border: 1px solid black;
  width: 154px;
  height: 53px;
  background-color: #ffffff;
  border: 1px solid #1B1B1B;
  border-radius: 0 10px 10px 0;
  color: #1B1B1B;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;


}

.booktimegreen {
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-family: Raleway;
  width: 164px;
  height: 53px;
  background-color: #ffffff;
  border: 1px solid rgb(45, 171, 36);
  border-radius: 10px;
  color: #009246;
  text-align: center;
  justify-content: center;
  align-items: center;


}
.bookatabletext {
  font-family: 'Lavishly Yours', sans-serif; /* Use quotes if the font name has spaces */
  text-align: center;
  letter-spacing: 1.5px;
  font-size: 96px;
}
.textdisply {
  font-family: 'Spectral SC', serif; /* Add quotes for font-family with spaces */
  font-size: 24px;
  text-align: center;
  width: 95%;
}

@media (max-width: 768px) {
  .pizzabookatable1-mobile {
    display: flex;
    flex-direction: column-reverse;
    height: auto !important;
    gap: 10px;
  }
  .pizzabookatable2-mobile{
    display: flex;
    flex-direction: column;
    height: auto !important;
    gap: 10px;
  }

  .timeDiv {
    display: flex;
    flex-direction: column;
    align-items:center;
    width: 95%;
    margin: auto;
    justify-content: center;
  }
  .pizzaImage2 {
    width: 95% !important;  
    overflow: hidden;
    margin: auto;
    border-radius: 15px;
  }
  .pizzaImage2 img{
   object-fit: cover; 
   overflow: hidden;
  
  }
  .bookatabletext{
    font-size: 50px;
  }

}

.bookatableContainer{
  margin-bottom: 30px;
}

