/* App.css */
.app-margin {
    margin-top: 120px;
     /* Default margin for mobile devices */
  }
  
  /* For larger screens */
  @media (max-width: 768px) {
    .app-margin {
      margin-top: 10px !important; /* Adjust the margin for tablets and larger devices */
     
    }
  }
  

  