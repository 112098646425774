/* Loading.css */
.loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    background-color: #f8f9fa; /* Light background color */
    font-size: 24px;
}

.loader {
    border: 6px solid #f3f3f3; /* Light grey */
    border-top: 6px solid #009246; /* Blue */
    border-radius: 50%;
    width: 40px; /* Reduced size */
    height: 40px; /* Reduced size */
    animation: spin 0.8s linear infinite; /* Reduced duration */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
