.location{
    width: 100%;
}
.location-title{
    text-align: center;
    color: #CD212A;
    font-size: 2rem;
    /* margin-top: 50px; */
}
.location-shop iframe{
    border: none;
}
.location-shop{
    width: 95%;
    margin: auto;
}
.location-search {
    display: flex;
    border: none;
    transition: border-color 0.3s;
    width: 95%;
    margin: auto;
    background-color:#009246 ;
    color: #fff;
    font-family: "roboto";
}
.location-search p{
    text-align: left;
    margin-left: 10px;
}
.location-search p > span{
 font-weight: bold;
 letter-spacing: 1px;
}

.location-search:hover {
    border-color: #007bff; /* Change border color on hover */
}

.location-search button {
    padding: 10px 15px;
    border: none;
    border-radius: 0 5px 5px 0;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.location-search button:hover {
    background-color: #0056b3; /* Darken on hover */
}
iframe {
    height: 400px !important; /* Adjust iframe height for smaller screens */
}

@media (max-width: 768px) {
    .location-title {
        font-size: 25px;
        padding: 0;
       /* padding-top: 150px; */
    
    }

    .location-search {
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    .location{
        padding-top: 10px;
    }
    .location-title {
        font-size: 25px;
        margin-bottom: 40px;
        margin-top: 15px;
    
    }

    .location-search {
        font-size: 14px;
    }

}

@media (max-width: 480px) {
    .location-title {
        font-size: 25px;
       
    }

    .location-search {
        font-size: 12px;
    }

    iframe {
        height: 250px; /* Adjust iframe height for smaller screens */
    }
}

@media (min-width: 375px) {
    .location-title {
        font-size: 2rem;

    }

    .location-search {
        font-size: 12px;
    }

    iframe {
        height: 250px; /* Adjust iframe height for smaller screens */
    }
}

