
.footer{
    width: 100%;
    /* height: 300px; */
    display: flex;
    flex-direction: column;
    background-color: #D2DADE;
    color: #000000;
    

}
.footer-content{
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin: auto;
    line-height: 10px;
    margin-top: 0;
    padding-bottom: 10px;
}
.footer-content h4{
    margin-bottom: 30px;
    font-weight: 300;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #263238;
    margin-top: 60px;
}
.footer-content p{
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    color: #263238;
    
}
.footer-contect{

}
.footer-logo{
    align-items: center;
    justify-content: center;
    margin: auto;
    display: flex;
    flex-direction: column;
}
.footer-download{
    align-items: center;
    display: flex;
    flex-direction: column;
}
.footer-bottom{
    width: 100%;
    height: 60px;
    background-color: #263238;
    font-family: "roboto";
    font-size: 1rem;
    color: #ffffff;
}
.footer-bottom{
    align-items: center;
    text-align: center;
    font-size: 16px;
    background-color: #426d35;
}
.footer-download-icon{
    display: flex;
    gap: 15px;
}
.footer-logo img{
    width: 50%;
    margin: auto;
}
.footer-logo h3{
 margin-top: 0;
 font-family: 'Reggae One', cursive;;
}
.footer a{
    text-decoration: none;
    color: #263238;
}
.footer-downloard-grayimg{
  filter: grayscale(100%);
   opacity: 0.5;

}


/* @media (max-width: 768px) {
  .footer-content{
    display: none;

}

.footer-mobile-1row{
    display: flex;
    width: 100%;
  }
  .footer-mobile .footer-main-log{
    display: flex;
    width: 50%;

  }
  .footer-mobile .footer-logo{
    display: flex;
    flex-direction: row;
  }
  .footer-social-media{
    width: 50%;
  }
  .footer-mobile .footer-logo img{
    width: 20%;
    margin: auto;
}

} */
 .footer-top-margin{
  height: 20px !important;
 }

@media (max-width: 768px) {
  .mobile-footer-row{
    display: flex;
    justify-content: space-around;
  }
  .mobile-footer-main-log,
   .mobile-footer-logo{
    display: flex;
    flex-direction: row;
  }
  .mobile-footer-logo img{
   display: flex;
   margin: auto;
   justify-content: center;
   width: 70px;
   height: 78px;
  }
  .footer-social-icon{
    display: flex;
    margin: auto;
    justify-content: center;
    gap: 10px;
  }
  .footer-social-icon a{
    margin-left: 10px;
  }
  .mobile-footer-row{
    justify-content: space-between;
    width: 90%;
    margin: auto;
  }
  .mobile-footer .footer-quick-links{
   width: 50%;
  }
  .mobile-footer .footer-contect{
  width: 50%;
  }
  .mobile-footer .footer-social-media{
    width: 50%;
  }
  .mobile-footer .footer-ligal{
    width: 50%;
    
  }
  .mobile-footer .footer-download{
    margin: auto;
    margin-bottom: 25PX;
  }
  .mobile-footer-logo h3{
    margin-top: 25px;
    margin-left: 10px;
  }
  .footer-downloard-grayimg{
    filter: grayscale(100%);
     opacity: 0.5;

  }
}