.download-main{
    padding-top: 20px;
}
.download{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 10px;
    
}
.download-title{
    text-align: center;
    color: #009246;
    font-size: 42px;
    font-weight: 600;
    width: 90%;
    margin: auto;
    justify-content: center;
}
.download-left{
    width: 35%;
    display: flex;
 
}
.download-right{
    width: 50%;
    margin-left: 25px;
}
.download-left img{
    width: 80%;
    justify-content: center;
    margin: auto;
    align-items: center;
    margin-left: 10%;
    left: 0;
}
.download-text{
    padding: 5px;
    margin-bottom: 20px;
}
.download-text h1{
 font-size: 4rem;
 margin-bottom: -10px;
 font-family: 'Roboto', sans-serif;
}
.download-text p{
    font-family: 'Roboto', sans-serif;
    color: #455A64;
    font-size: 14px;
    letter-spacing: 0.5;
    line-height: 12px;
   }
   .download-text-list li{
    font-family: 'Roboto', sans-serif;
    color: #455A64;
    font-size: 14px;
    padding: 0;
   }
.download-icon{
 display: flex;
 gap:5%;
}
@media (max-width:768px){
    .download{
        flex-direction: column;
        
    }
    .download-left{
        width: 90%;
        margin: auto;
    }
    .download-right{
        width: 90%;
        margin-left: 20px;
   
       
        
       
    }
    .download-text h1{
        font-size: 2rem;
        margin-bottom: 10px;
        text-align: center;

       }
  
       

       
       .download-left img{
        display: flex;
        width: 90%;
        margin: auto;


    }
    .download-text{
        padding: 5px;
        margin-bottom: 10px;
        
    }
    .download-text p{
        font-family: 'Roboto', sans-serif;
        color: #455A64;
        font-size: 14px;
        letter-spacing: 0.2;
        line-height: 20px;
       }

           .download-icon {
               display: flex;
    
    
               justify-content: center;
               padding-bottom: 5px;
    
           }
       .download-title{
        padding-top: 20px;
        font-size: 30px;
       }
       
      
}

@media (max-width: 376px) {
    .download {
        flex-direction: column;
        height: auto; /* Adjust height as necessary */
       
        background-color: #ffffff;
    }
    .download-left {
        width: 90%;
        margin: auto;
    }
    .download-right {
        width: 90%;
    }
    .download-text h1 {
        font-size: 2.5rem; /* Adjust size for smaller screens */
        margin-bottom: 10px;
        text-align: center;
    }
    .download-left img {
        display: flex;
        width: 80%;
        margin: auto;
    }
    .download-text {
        padding: 5px;
        margin-bottom: 10px;
    }
    .download-text p {
        font-family: 'Roboto', sans-serif;
        color: #455A64;
        font-size: 12px; /* Adjust size for readability */
        letter-spacing: 0.2;
        line-height: 18px;
    }
    .download-icon {
        display: flex;
        justify-content: center;
        padding-bottom: 5px;
    }
    .download-title {
        padding-top: 0px;
        font-size: 25px; /* Adjust for smaller screens */
    }
}
